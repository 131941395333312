<template>
  <div class="scored-quiz">
    <table cellpadding="0" cellspacing="2">
      <tr class="header">
        <th colspan="2" class="title">{{config.title}}</th>
        <th v-for="(answer, index) in config.answers" :key="index" class="answer">{{answer}}</th>
      </tr>
      <tr v-for="(question, index) in config.questions" :key="index">
        <td class="questionNo" :class="validClass(index)">{{index+1}}</td>
        <td class="question" :class="validClass(index)">{{question.text}}</td>
        <td v-for="(score, index) in question.scores" :key="index" class="radioDiv">
          <input
            type="radio"
            :disabled="!config.editTotal && showTotalMessage"
            :name="question.id+1"
            :value="score"
            @change="responseChanged"
          />
          <span v-show="config.showScores && showTotalMessage" class="answerScore">{{score}}</span>
        </td>
      </tr>
    </table>
    <button class="calcButton" v-show="!showTotalMessage" @click="calculateTotal">{{buttonLabel}}</button>
    <div v-show="showTotalMessage" class="message">{{totalMessage}}</div>
  </div>
</template>

<script>
export default {
  name: "ScoredQuiz",

  props: {
    title: {
      type: String,
      default: "Green Quiz"
    },
    answers: {
      type: String,
      default: '"Never", "Sometimes", "Always"'
    },
    questions: {
      type: String,
      default:
        '"Do you turn off unnecessary lights?", "Do you turn off the taps while brushing your teeth?", "Do you run your heating on a timer?", "Do you turn off your heating when you go out?"'
    },
    scores: {
      type: String,
      default: "[0,1,2], [3,2,1], [2,1,0], [3,2,1]"
    },
    message: {
      type: String,
      default: "Your total score was {total}"
    },
    buttonLabel: {
      type: String,
      default: "Calculate my total"
    },
    editTotal: {
      type: String,
      default: "false"
    },
    showScores: {
      type: String,
      default: "true"
    }
  },

  data() {
    return {
      responses: {},
      edited: false,
      totalChecked: false,
      total: 0
    };
  },

  computed: {
    config() {
      const answers = JSON.parse(`[${this.answers}]`);
      const questionsArray = JSON.parse(`[${this.questions}]`);
      const scores = JSON.parse(`[${this.scores}]`);
      const questions = [];
      for (let q = 0; q < questionsArray.length; q++) {
        const question = questionsArray[q];
        questions.push({
          id: q,
          text: question,
          scores: scores[q]
        });
      }
      return {
        title: this.title,
        questions: questions,
        answers: answers,
        editTotal: this.editTotal.toLowerCase() == "true",
        showScores: this.showScores.toLowerCase() == "true"
      };
    },
    showTotalMessage() {
      return (
        this.totalChecked &&
        !this.edited &&
        Object.keys(this.responses).length >= this.config.questions.length
      );
    },
    totalMessage() {
      return this.message.replace("{total}", this.total);
    }
  },

  methods: {
    responseChanged(e) {
      this.edited = true;
      this.$set(
        this.responses,
        "question-" + e.target.name,
        parseInt(e.target.value)
      );
    },
    validClass(index) {
      return this.totalChecked &&
        // this.responses.hasOwnProperty("question-" + (index + 1))
        !("question-" + (index + 1) in this.responses)
        ? "invalid"
        : "valid";
    },

    calculateTotal() {
      this.edited = false;
      let total = 0;
      for (let key in this.responses) {
        total += this.responses[key];
      }
      this.total = total;
      this.totalChecked = true;
    }
  }
};
</script>

<style>
.scored-quiz {
  width: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.scored-quiz .header th {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}
.scored-quiz .title {
  text-align: left;
  font-size: 1.2em;
}
.scored-quiz table {
  width: 100%;
}
.scored-quiz table tr:nth-child(odd) {
  background-color: #f2f2f2;
}
.scored-quiz table tr:nth-child(even) {
  background-color: #fdfafa;
}
.scored-quiz .questionNo {
  font-weight: bold;
}
.scored-quiz table th {
  color: #fff;
  background: #a0ce4e;
  padding: 20px;
}
.scored-quiz table td {
  padding: 10px;
}
.scored-quiz .question {
  text-align: left;
}
.scored-quiz .radioDiv {
  text-align: center;
}
.scored-quiz .answer {
  text-align: center;
}
.scored-quiz .answerScore {
  font-size: 0.8em;
  position: absolute;
  padding: 3px;
}
.scored-quiz .invalid {
  color: red;
}
.scored-quiz .calcButton {
  padding: 10px;
  margin-right: 20px;
  font-size: 1em;
  font-weight: bold;
  color: #fff;
  background-color: #a0ce4e;
  margin-top: 10px;
}
.scored-quiz .message {
  margin-top: 10px;
  padding: 10px;
  font-weight: bold;
  border: 1px dashed #a0ce4e;
  text-align: center;
}
</style>